<template>
  <div class="app-contianer">
    <div class="page-bg">
      <div class="bg"></div>
    </div>
    <div id="app-content">
      <!-- <div class="page-item account-box mainBgColor">
        <div>请绑定充值户号</div>
        <div>切换户号<span class="page-icon arrows-right"></span></div>
      </div> -->

      <div class="type-box">
        <div class="item" :class="status === item.value ? 'active':''" v-for="item in statusMap" :key="item.value"
          @click="changeStatus(item)">
          {{item.label}}
        </div>
      </div>

      <div class="record-box">
        <div class="page-item" v-for="item in orderList" :key="item.orderId">
          <div class="orderId f36">订单号: {{item.orderId}}</div>
          <div class="info-box">
            <div class="page-icon"></div>
            <div class="info">
              <p class="f40"><strong>充值{{item.amount}}元, 应付{{item.realAmount}}元</strong></p>
              <p class="f36">{{item.createTime | timeFliter}}</p>
              <p class="f36">手机号: {{item.smstitle}}</p>
            </div>
            <div class="type primary f36">{{item.orderStatusName}}</div>
          </div>
        </div>
      </div>

      <div class="scrollTip">{{scrollTip}}</div>
    </div>
  </div>
</template>

<script>
import { mallApi } from '@/api'
import { dateFormat } from '../../../utils/utils'

const statusMap = Object.freeze([
  { value: '', label: '全部' },
  { value: 'PAYINT', label: '待付款' },
  { value: 'PAIED', label: '已付款' },
  { value: 'FINISH', label: '已完成' },
])
export default {
  name: 'tariffeRecord',
  data() {
    return {
      statusMap: statusMap,
      status: '',
      orderList: [],
      page: 1,
      pageSize: 10,
      pageFlag: false, //分页是否完成
      scrollTip: ''
    }
  },
  created() {
    this.getOrderList()
  },
  filters: {
    timeFliter(val) {
      return dateFormat(new Date(val))
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollListener)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollListener)
  },
  methods: {
    // 获取订单列表
    getOrderList() {
      let params = {
        trxCode: 'XF340',
        orderProduct: '1014',
        status: this.status,
        page: this.page,
        pageSize: this.pageSize
      }
      mallApi(params).then(res => {
        const { rspCode, data, rspMsg } = res
        if (rspCode === '0000') {
          if (data.length < this.pageSize && this.page != 1) {
            this.pageFlag = true
            this.scrollTip = '没有更多了'
          } else {
            this.orderList = this.orderList.concat(data)
            console.log(this.orderList);
          }
        } else {
          this.$toast(rspMsg)
        }
      })
    },

    // 切换列表
    changeStatus(item) {
      this.status = item.value
      this.pageFlag = false
      this.scrollTip = ''
      this.page = 1
      this.orderList = []
      this.getOrderList()
    },

    // 滚动监听
    scrollListener() {
      //总高度=滚动条滚动的高度+cliebtHeight（要是想不到底部就加载数据可以写成）
      //offsetHeight +clientHeight>=scrollHeight-n (n是任意数字)
      const clientHeight = document.documentElement.clientHeight
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const scrollHeight = document.body.scrollHeight
      var hegit = clientHeight + scrollTop
      var scroll = scrollHeight - 100
      if (hegit >= scroll) {
        if (this.pageFlag === false) {
          this.page = this.page + 1
          this.getOrderList()
        }
      }
    },
  }
}
</script>

<style lang='less' scoped>
.account-box {
  padding: 32px 18px;
  display: flex;
  justify-content: space-between;
  color: #fff;

  .page-icon.arrows-right {
    width: 9px;
    height: 14px;
    background-position: -77px -122px;
    margin-left: 8px;
  }
}
.type-box {
  display: flex;
  justify-content: space-between;
  margin: 12px;

  .item {
    color: #666;
  }
  .item.active {
    font-weight: bold;
    color: #333;
  }
}
.record-box {
  .orderId {
    color: #666;
    margin-bottom: 8px;
  }
  .info-box {
    display: flex;
    align-items: center;

    .page-icon {
      width: 54px;
      height: 54px;
      background-position: -517px -75px;
    }
    .info {
      margin: 0 12px;
      flex: 1;
      color: #666;

      .f40 {
        color: #333;
      }
    }
    .type {
      padding: 4px 12px;
      border-radius: 4px;
    }
    .type.primary {
      background: #e8efff;
      color: #3b75ff;
    }
  }
}
.scrollTip {
  text-align: center;
  color: #999;
  margin-top: 24px;
}
</style>